const qrcode = require("qrcode");
const btn = document.getElementById("btn");
const text = document.getElementById("text");
const anchor = document.querySelector("a");
const historyElem = document.getElementById("history");
const result = document.getElementById("qr-code");

let historyVal = [];

function history() {
  let history = JSON.parse(localStorage.getItem("history"));
  if (history != null) {
    historyVal = JSON.parse(localStorage.getItem("history"));
    historyElem.innerHTML = "";
    if (history.length >= 1) {
      historyVal.forEach((item) => {
        historyElem.innerHTML += `
          <div class="history__item">
            <div class="code">
              ${item[0]}
            </div>
            <div class="data">
              <div class="date">Date: ${item[1]}</div>
              <div class="link">Content: ${item[2]}</div>
            </div>
            <a id="download" href="${item[3]}" download>
            <iconify-icon icon="ic:round-download"></iconify-icon>
            </a>
          </div>
        `;
      });
    }
  } else {
    localStorage.setItem("history", JSON.stringify([]));
    historyVal = JSON.parse(localStorage.getItem("history"));
  }
}

history();

btn.addEventListener("click", () => {
  if (text.value != "") {
    anchor.classList.remove("disabled");
    text.classList.remove("error");
    let value = "";
    let link = "";
    qrcode.toString(text.value, function (err, string) {
      result.innerHTML = string;
      value = string;
    });
    qrcode.toDataURL(text.value, function (err, dataUrl) {
      // Pobierz przycisk pobierania
      var downloadButton = document.getElementById("download-button");
      // Ustaw atrybut "href" na obrazek kodu QR jako ciąg danych base64
      link = dataUrl;
      downloadButton.href = dataUrl;
    });
    historyVal.push([value, new Date().toLocaleString(), text.value, link]);
    localStorage.setItem("history", JSON.stringify(historyVal));
    history();
  } else {
    text.classList.remove("error");
    text.classList.add("error");
  }
});

document.getElementById("clear").addEventListener("click", () => {
  historyVal = [];
  historyElem.innerHTML = "";
  localStorage.setItem("history", JSON.stringify([]));
});
